import { get, keyBy, keys } from 'lodash';

import {
  FUNNEL_ANALYTICS_KEY_MAP,
  SANKEY_MINIMUM_HEIGHTS as MINIMUM_HEIGHTS
} from '../../constants';
import { getPercentage } from '../helpers';

export const generateFlagsData = (rawData, workflow = {}) => {
  let transformedData = [];
  let totalFlagCount = 0;
  const modulesJson = keyBy(workflow.modules, 'id');
  rawData.forEach(({ flag, count, moduleId }) => {
    totalFlagCount += count;
    let label = flag;
    if (moduleId) {
      const moduleName = get(modulesJson, [moduleId, 'name']);
      label += ` - ${moduleName || moduleId}`;
    }
    transformedData.push({ label, value: count, flag, moduleId });
  });

  transformedData = transformedData.map((flag) => ({
    ...flag,
    value: `${getPercentage(flag.value, totalFlagCount)}%`,
    width: (flag.value / transformedData[0].value) * 100
  }));

  return transformedData;
};

export const getRates = (value) => (Number.isNaN(value) ? '0%' : `${Math.ceil(value)}%`);

export const computePercentagesForFunnel = (data, keyMap = FUNNEL_ANALYTICS_KEY_MAP) => {
  const percentages = keys(data).reduce((accumulator, key) => {
    const targetKey = keyMap[key] || key;
    accumulator[targetKey] = getPercentage(data[key], data.unique);
    return accumulator;
  }, {});

  return percentages;
};

export const calculateKeyMetrics = (data) => {
  const conversion = getPercentage(data.autoApproved + data.manualAccepted, data.unique);

  const autoApproval = getPercentage(
    data.autoApproved,
    data.autoApproved + data.autoRejected + data.manualReview
  );
  const automation = getPercentage(
    data.autoApproved + data.autoRejected,
    data.autoApproved + data.autoRejected + data.manualReview
  );

  return {
    conversion: getRates(conversion),
    autoApproval: getRates(autoApproval),
    automation: getRates(automation)
  };
};

export const getSankeyWeights = (data) => {
  return {
    'Unique Users': get(data, 'unique', 0),
    Started:
      get(data, 'started', 0) ||
      get(data, 'unique', 0) - get(data, 'completed', 0) - get(data, 'lost', 0),
    'Completed Users': get(data, 'completed', 0),
    'Users Lost': get(data, 'lost', 0),
    'Auto Approved': get(data, 'autoApproved', 0),
    'Needs Review': get(data, 'manualReview', 0),
    'Manually Approved': get(data, 'manualAccepted', 0),
    'Manually Declined': get(data, 'manualRejected', 0),
    'Auto Declined': get(data, 'autoRejected', 0),
    Error: get(data, 'error', 0),
    'Drop Off': get(data, 'dropOff', 0),
    'Pending Review': get(data, 'pendingReview', 0)
  };
};

export const getSankeyLinks = (data, weightObj) => {
  const links = [
    {
      source: `Unique Users`,
      target: `Completed Users`,
      value: Math.max(get(data, 'completed', 0), MINIMUM_HEIGHTS.COMPLETED)
    },
    {
      source: `Unique Users`,
      target: `Started`,
      value: Math.max(get(data, 'started', 0), weightObj.Started, MINIMUM_HEIGHTS.STARTED)
    },
    {
      source: `Unique Users`,
      target: `Users Lost`,
      value: Math.max(get(data, 'lost', 0), MINIMUM_HEIGHTS.LOST)
    },
    {
      source: `Completed Users`,
      target: `Auto Approved`,
      value: Math.max(get(data, 'autoApproved', 0), MINIMUM_HEIGHTS.AUTO_APPROVED)
    },
    {
      source: `Completed Users`,
      target: `Needs Review`,
      value: Math.max(get(data, 'manualReview', 0), MINIMUM_HEIGHTS.MANUAL_REVIEW)
    },
    {
      source: `Needs Review`,
      target: `Manually Declined`,
      value: Math.max(get(data, 'manualRejected', 0), MINIMUM_HEIGHTS.MANUAL_REJECTED)
    },
    {
      source: `Needs Review`,
      target: `Manually Approved`,
      value: Math.max(get(data, 'manualAccepted', 0), MINIMUM_HEIGHTS.MANUAL_ACCEPTED)
    },
    {
      source: `Needs Review`,
      target: `Pending Review`,
      value: Math.max(get(data, 'pendingReview', 0), MINIMUM_HEIGHTS.PENDING_REVIEW)
    },
    {
      source: `Completed Users`,
      target: `Auto Declined`,
      value: Math.max(get(data, 'autoRejected', 0), MINIMUM_HEIGHTS.AUTO_REJECTED)
    },
    {
      source: `Users Lost`,
      target: `Error`,
      value: Math.max(get(data, 'error', 0), MINIMUM_HEIGHTS.ERROR)
    },
    {
      source: `Users Lost`,
      target: `Drop Off`,
      value: Math.max(get(data, 'dropOff', 0), MINIMUM_HEIGHTS.DROP_OFF)
    }
  ];

  return links;
};

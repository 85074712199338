export const nodes = [
  {
    id: 'unique',
    type: 'custom',
    data: {
      id: 'unique',
      label: 'Unique Users'
    }
  },
  {
    id: 'started',
    type: 'custom',
    data: {
      id: 'started',
      label: 'Started Users'
    }
  },
  {
    id: 'completed',
    type: 'custom',
    data: {
      id: 'completed',
      label: 'Completed Users'
    }
  },
  {
    id: 'lost',
    type: 'custom',
    data: {
      id: 'lost',
      label: 'Users Lost'
    }
  },
  {
    id: 'autoApproved',
    type: 'custom',
    data: {
      id: 'autoApproved',
      label: 'Auto Approved'
    }
  },
  {
    id: 'autoRejected',
    type: 'custom',
    data: {
      id: 'autoRejected',
      label: 'Auto Declined'
    }
  },
  {
    id: 'manualReview',
    type: 'custom',
    data: {
      id: 'manualReview',
      label: 'Needs Review'
    }
  },
  {
    id: 'manualAccepted',
    type: 'custom',
    data: {
      id: 'manualAccepted',
      label: 'Manually Approved'
    }
  },
  {
    id: 'manualRejected',
    type: 'custom',
    data: {
      id: 'manualRejected',
      label: 'Manually Declined'
    }
  },
  {
    id: 'pendingReview',
    type: 'custom',
    data: {
      id: 'pendingReview',
      label: 'Pending Review'
    }
  },
  {
    id: 'error',
    type: 'custom',
    data: {
      id: 'error',
      label: 'Error'
    }
  },
  {
    id: 'dropOff',
    type: 'custom',
    data: {
      id: 'dropOff',
      label: 'Drop Off'
    }
  }
];

export const edges = [
  {
    id: 'unique_started',
    source: 'unique',
    target: 'started',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'unique_completed',
    source: 'unique',
    target: 'completed',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'unique_lost',
    source: 'unique',
    target: 'lost',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'completed_approved',
    source: 'completed',
    target: 'autoApproved',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'completed_review',
    source: 'completed',
    target: 'manualReview',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'completed_declined',
    source: 'completed',
    target: 'autoRejected',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'review_accepted',
    source: 'manualReview',
    target: 'manualAccepted',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'review_rejected',
    source: 'manualReview',
    target: 'manualRejected',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'review_pending',
    source: 'manualReview',
    target: 'pendingReview',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'lost_error',
    source: 'lost',
    target: 'error',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  },
  {
    id: 'lost_drop',
    source: 'lost',
    target: 'dropOff',
    sourceHandle: 'a',
    markerEnd: 'arrowclosed'
  }
];

import React, { useState } from 'react';

import { Spinner } from 'clm-components';
import PropTypes from 'prop-types';
import { Col, Popover, Row } from 'react-bootstrap';

import HotspotSidePanel from './HotspotSidePanel';
import InfoIcon from '../../../../assets/InfoIcon.svg';
import noDataImage from '../../../../assets/nodata.png';
import './FunnelAnalytics.css';
import { getAnalyticsLanding } from '../../../../utils/helpers';
import AnalyticsSpinner from '../../../Shared/AnalyticsSpinner';
import ProgressDiv from '../../../Shared/ProgressDiv';
import CommonPopover from '../CommonPopover';

function FunnelAnalytics({
  ref,
  chartDivRef,
  loadingSankey,
  loadingFlags,
  noData,
  conversionRate,
  autoapprovalRate,
  automationRate,
  medianTimeSpent,
  manualReviewFlagsData,
  autoRejectedFlagsData,
  errorFlagsData,
  firstLoad,
  showErrorContent,
  onFiltersApply,
  filters,
  isHotspotClickDisabled
}) {
  const [showHotspotSidePanel, setShowHotspotSidePanel] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');
  const [activeHotspot, setActiveHotspot] = useState('');
  const [currentStatusHotspots, setCurrentStatusHotspots] = useState([]);

  const handleHotspotSelection = (hotspot) => {
    setStatusSelected(hotspot);
    let hotspotData;

    if (hotspot === 'manualReview') hotspotData = manualReviewFlagsData;
    else if (hotspot === 'autoRejected') hotspotData = autoRejectedFlagsData;
    else hotspotData = errorFlagsData;

    setCurrentStatusHotspots(hotspotData);
  };

  const onHotspotClick = (event, hotspotId, hotspotLabel) => {
    setShowHotspotSidePanel(true);
    setActiveHotspot(hotspotLabel);
  };

  const onHotspotSidePanelClose = (event) => {
    event.stopPropagation();
    setShowHotspotSidePanel(false);
  };

  return (
    <div className="funnel-analytics-parent">
      {firstLoad || showErrorContent ? (
        <div className="funnel-analytics-landing-div">
          {getAnalyticsLanding(showErrorContent, onFiltersApply)}
        </div>
      ) : (
        <>
          <div ref={ref} className="analytics-data-div">
            <HotspotSidePanel
              showSidePanel={showHotspotSidePanel}
              onSidePanelClose={onHotspotSidePanelClose}
              statusSelected={statusSelected}
              currentStatusHotspots={currentStatusHotspots}
              activeHotspot={activeHotspot}
              filters={filters}
              isHotspotClickDisabled={isHotspotClickDisabled}
            />
            <h5> Funnel Overview </h5>
            <p>Bird&apos;s eye view on how did the applications get processed by our systems</p>
            <div ref={chartDivRef} id="analytics-chart-div" className="analytics-chart-div" />
            {loadingSankey && (
              <div className="loading-sankey-div">
                <AnalyticsSpinner />
              </div>
            )}
            {noData && !loadingSankey && (
              <div className="no-data-div">
                <img src={noDataImage} alt="Not enough data" />
                <p>Not enough data to show insights here</p>
              </div>
            )}
            <Row className="analytics-data-row">
              <Col className="analytics-data-col">
                <div>
                  <p>
                    Auto Approval Rate
                    <CommonPopover
                      trigger={['hover']}
                      popoverClass="funnel-popover"
                      body={
                        <Popover.Body>
                          Auto Approval Rate : (i) Ratio of customers auto approved to customers
                          completing the journey. (ii) Auto Approved / (Auto Approved + Auto
                          Declined + Needs Review)
                        </Popover.Body>
                      }
                      icon={InfoIcon}
                    />
                  </p>
                  <h5>{loadingSankey ? <Spinner /> : autoapprovalRate}</h5>
                </div>
              </Col>
              <Col className="analytics-data-col">
                <div>
                  <p>
                    Conversion Rate
                    <CommonPopover
                      trigger={['hover']}
                      body={
                        <Popover.Body>
                          Conversion Rate: (i) Ratio of customers approved in total to customers
                          starting the journey. (ii) (Manually Approved + Auto Approved) / Unique
                          Users
                        </Popover.Body>
                      }
                      icon={InfoIcon}
                    />
                  </p>
                  <h5>{loadingSankey ? <Spinner /> : conversionRate}</h5>
                </div>
              </Col>
              <Col className="analytics-data-col">
                <div>
                  <p>
                    Automation Rate
                    <CommonPopover
                      trigger={['hover']}
                      body={
                        <Popover.Body>
                          Automation Rate: (i) Ratio of customers (Auto approved + Auto declined) /
                          (Auto approved + Auto declined + Needs review).
                        </Popover.Body>
                      }
                      icon={InfoIcon}
                    />
                  </p>
                  <h5>{loadingSankey ? <Spinner /> : automationRate}</h5>
                </div>
              </Col>
              <Col className="analytics-data-col">
                <div>
                  <p>
                    Onboarding Time (Beta)
                    <CommonPopover
                      trigger={['hover']}
                      body={
                        <Popover.Body>
                          Median time taken by users to complete their KYC journey
                        </Popover.Body>
                      }
                      icon={InfoIcon}
                    />
                  </p>
                  <h5>{loadingFlags ? <Spinner /> : medianTimeSpent}</h5>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="flag-stats-row">
            <Col
              onClick={(event) => {
                event.stopPropagation();
                handleHotspotSelection('manualReview');
              }}
              xl={4}>
              <ProgressDiv
                onClick={onHotspotClick}
                isHotspotClickDisabled={isHotspotClickDisabled}
                isLoading={loadingFlags}
                title="Needs Review  - Top Reasons"
                subtitle="Reasons why an application gets highlighted for manual review"
                values={manualReviewFlagsData}
                color="linear-gradient(to right, rgba(238, 210, 87, 0.8) 0%, rgba(238, 210, 87, 0.05) 100%)"
              />
            </Col>
            <Col
              onClick={(event) => {
                event.stopPropagation();
                handleHotspotSelection('autoRejected');
              }}
              xl={4}>
              <ProgressDiv
                onClick={onHotspotClick}
                isHotspotClickDisabled={isHotspotClickDisabled}
                isLoading={loadingFlags}
                title="Auto Decline - Top Reasons"
                subtitle="Reasons why an application gets automatically declined by our systems"
                values={autoRejectedFlagsData}
                color="linear-gradient(to right, #de4a70 0%, rgba(222, 74, 112, 0.05) 100%)"
              />
            </Col>
            <Col
              onClick={(event) => {
                event.stopPropagation();
                handleHotspotSelection('error');
              }}
              xl={4}>
              <ProgressDiv
                onClick={onHotspotClick}
                isHotspotClickDisabled={isHotspotClickDisabled}
                isLoading={loadingFlags}
                title="Error - Top Reasons"
                subtitle="Reasons why an application might run into an error while processing"
                values={errorFlagsData}
                color="linear-gradient(to right, #F24E1E 0%, rgba(222, 74, 112, 0.20) 100%)"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

FunnelAnalytics.defaultProps = {
  loadingSankey: false,
  loadingFlags: false,
  showErrorContent: false,
  onFiltersApply: () => {},
  isHotspotClickDisabled: false
};

FunnelAnalytics.propTypes = {
  ref: PropTypes.func.isRequired,
  chartDivRef: PropTypes.func.isRequired,
  loadingSankey: PropTypes.bool,
  loadingFlags: PropTypes.bool,
  noData: PropTypes.bool.isRequired,
  conversionRate: PropTypes.string.isRequired,
  autoapprovalRate: PropTypes.string.isRequired,
  automationRate: PropTypes.string.isRequired,
  medianTimeSpent: PropTypes.string.isRequired,
  manualReviewFlagsData: PropTypes.array.isRequired,
  autoRejectedFlagsData: PropTypes.array.isRequired,
  errorFlagsData: PropTypes.array.isRequired,
  firstLoad: PropTypes.bool.isRequired,
  showErrorContent: PropTypes.bool,
  onFiltersApply: PropTypes.func,
  filters: PropTypes.object.isRequired,
  isHotspotClickDisabled: PropTypes.bool
};

export default FunnelAnalytics;

import React from 'react';

import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';

import './Modal.css';

function Modal({ show, onHide, children, className }) {
  if (!show) return null;

  return (
    <div role="presentation" onClick={onHide} className="modal-overlay">
      <div className={`modal-container ${className}`}>
        <IoMdClose onClick={onHide} className="modal-close-button" />
        {children}
      </div>
    </div>
  );
}

function Header({ children, className }) {
  return <div className={`modal-header ${className}`}>{children}</div>;
}

function Body({ children, className }) {
  return (
    <div
      role="presentation"
      onClick={(event) => event.stopPropagation()}
      className={`modal-body ${className}`}>
      {children}
    </div>
  );
}

function Footer({ children, className }) {
  return <div className={`modal-footer ${className}`}>{children}</div>;
}

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

Modal.defaultProps = {
  show: false,
  className: ''
};

Modal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Header.defaultProps = {
  className: ''
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Body.defaultProps = {
  className: ''
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Footer.defaultProps = {
  className: ''
};
Footer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Modal;

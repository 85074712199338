import React from 'react';

import PropTypes from 'prop-types';

import Modal from '../../../../Shared/Modal';

function KnowMoreModal({ showModal, onHide }) {
  return (
    <Modal className="know_more_modal" show={showModal} onHide={onHide}>
      <Modal.Header className="know_more_modal_header">
        <h5>Know More</h5>
      </Modal.Header>
      <Modal.Body className="know_more_modal_body">
        <div className="know_more_modal_item_container">
          <h5>Application status</h5>
          <p>
            &apos;Status&apos; shown against a particular Transaction ID shows the latest status
            that the user is currently in. During a journey, a user could have gone through multiple
            statuses depending on their interactions. <br />
            <br />
            For example: Alice attempted a journey and initially reached the status &apos;User
            cancelled&apos;. Alice can now retry the journey and end up in the &apos;Auto
            approved&apos; status. In this case, the table shows the status against Alice as
            &apos;Auto approved&apos;.
          </p>
        </div>
        <div className="know_more_modal_item_container">
          <h5>Flags</h5>
          <p>
            The &apos;Flags from workflow&apos; section under a particular Transaction ID will
            display errors that occurred only in the latest attempt of a step. <br />
            <br />
            For example: Bob attempted the &apos;Selfie liveness step&apos; (1st attempt) and ended
            up with a flag &apos;Eyes closed in selfie&apos;. Bob can now re-attempt the
            &apos;Selfie liveness step&apos; (2nd attempt) and end up with the flag &apos;Face
            blurred&apos;. In this case, the &apos;Flags from workflow&apos; section will show only
            the latest flag from the step &apos;Face blurred&apos;.
          </p>
        </div>
        <div className="know_more_modal_item_container">
          <h5>Business metrics</h5>
          <p>
            Under Business metrics -&gt; Funnel Overview, we show the latest status that the users
            are in <br />
            <br />
            Under Business metrics -&gt; Top reasons (Manual review / Auto-rejections / Error), we
            display the &apos;Top reasons&apos; why a user went into the particular status
            historically. This will contain &apos;Application status&apos; and &apos;Flags&apos;
            from all attempts (including earlier attempts) of the user.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

KnowMoreModal.defaultProps = {
  showModal: false
};

KnowMoreModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func.isRequired
};

export default KnowMoreModal;

export const NODE_TYPES = {
  CONDITION: 'condition',
  OUTPUT: 'output',
  INPUT: 'input',
  MANUAL_REVIEW: 'manualReview',
  DYNAMIC_FORM: 'dynamicForm',
  DYNAMIC_FORM_V2: 'dynamicFormV2',
  DEFAULT: 'default',
  USER_CANCELLED: 'user_cancelled'
};

export const NODES_CLASSNAMES = {
  OUTPUT: 'nodes__module_output',
  DEFAULT: 'nodes__module'
};

export const EDGE_TYPES = {
  CONDITION: 'conditionEdge',
  MODULE: 'moduleEdge'
};

export const defaultMetrics = {
  uniqueUsers: 0,
  medianTimeSpent: 0,
  medianAttempts: 0,
  medianResponseTime: 0,
  successCalls: 0,
  failedCalls: 0,
  manualReview: 0,
  autoRejected: 0,
  totalCalls: 0
};

export const trueEdgeColor = {
  bg: '#f2fcf9',
  label: '#064'
};
export const falseEdgeColor = {
  bg: '#f2fcf9',
  label: '#064'
};
export const manualReviewEdgeColor = {
  bg: '#fff4dc',
  label: '#9a4c24'
};
export const autoDeclinedEdgeColor = {
  bg: '#ffebe6',
  label: '#bf2600'
};

export const userCancelledEdgeColor = {
  bg: '#d9dcde',
  label: '#05055299'
};

export const SANKEY_MINIMUM_HEIGHTS = {
  COMPLETED: 50,
  STARTED: 30,
  LOST: 20,
  AUTO_APPROVED: 10,
  MANUAL_REVIEW: 30,
  MANUAL_REJECTED: 10,
  MANUAL_ACCEPTED: 10,
  PENDING_REVIEW: 10,
  AUTO_REJECTED: 10,
  ERROR: 10,
  DROP_OFF: 10
};

export const SANKEY_LABELS = {
  unique: { id: `Unique Users`, color: '#7c80ee' },
  started: { id: `Started`, color: '#7c80ee' },
  completed: { id: `Completed Users`, color: `#7fcba7` },
  lost: { id: `Users Lost`, color: `#d9d9e4` },
  autoApproved: { id: `Auto Approved`, color: `#7fcba7` },
  manualReview: { id: `Needs Review`, color: `#fae076` },
  manualAccepted: { id: `Manually Approved`, color: `#7fcba7` },
  manualRejected: { id: `Manually Declined`, color: `#f59c8a` },
  pendingReview: { id: `Pending Review`, color: `#fae076` },
  autoRejected: { id: `Auto Declined`, color: `#f59c8a` },
  error: { id: `Error`, color: `#f59c8a` },
  dropOff: { id: `Drop Off`, color: `#000` }
};

export const FILTER_KEYS = {
  WORKFLOW_ID: 'workflowId'
};

export const APPLICATION_REDIRECT_STATUS = [
  'started',
  'error',
  'user_cancelled',
  'kyc_in_progress'
];

export const FUNNEL_ANALYTICS_KEY_MAP = {
  unique: 'Unique Users',
  completed: 'Completed Users',
  started: 'Started',
  lost: 'Users Lost',
  autoApproved: 'Auto Approved',
  manualReview: 'Needs Review',
  manualAccepted: 'Manually Approved',
  manualRejected: 'Manually Declined',
  pendingReview: 'Pending Review',
  autoRejected: 'Auto Declined',
  error: 'Error',
  dropOff: 'Drop Off'
};

export const END_STATES = [
  'approve',
  'decline',
  'manualReview',
  'auto_approved',
  'auto_declined',
  'needs_review',
  'user_cancelled'
];
export const AUTO_APPROVED = ['approve', 'auto_approved'];
export const MANUAL_REVIEW = ['manualReview', 'needs_review'];
export const AUTO_DECLINED = ['decline', 'auto_declined'];
export const USER_CANCELLED = ['user_cancelled'];

export const WARNING_BANNER_MESSAGES = {
  MISSING_MODULE_NAME:
    'Some module names might be missing in this workflow. To improve your experience, reach out to Hyperverge support.”'
};

export const WARNING_BANNER_IDS = {
  MISSING_MODULE_NAME: 'missing_module_names_warning_toast'
};

export const DEFAULT_RETENTION_DAYS = 14;

export const CUSTOM_REPORT_ERROR_CODES = {
  metabase: {
    NO_STATIC_EMBEDDING_LINKS_FOUND: 'NO_STATIC_EMBEDDING_LINKS_FOUND',
    STATIC_EMBEDDING_UNKNOWN_API_ERROR: 'STATIC_EMBEDDING_UNKNOWN_API_ERROR'
  }
};
